var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.klist,attrs:{"fixed-header":"","dense":"","single-select":"","items":_vm.items,"item-key":"id","headers":_vm.selection,"loading":_vm.Loading,"loading-text":"Chargement en cours ... Merci de patienter","options":_vm.options,"server-items-length":_vm.total,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItems,
      'disable-pagination': true,
    },"hide-default-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-menu',{attrs:{"bottom":"","right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"indigo","dark":"","disabled":_vm.Loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[(_vm.list_options)?_c('v-radio-group',{on:{"change":function($event){return _vm.ShowMore()}},model:{value:(_vm.expire),callback:function ($$v) {_vm.expire=$$v},expression:"expire"}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-radio',{attrs:{"value":_vm.expire1}})],1),_c('v-list-item-title',[_vm._v("En cours ")])],1),(_vm.isExpire)?_c('v-list-item',[_c('v-list-item-action',[_c('v-radio',{attrs:{"value":_vm.expire2}})],1),_c('v-list-item-title',[_vm._v("Expire ")])],1):_vm._e(),_c('v-list-item',[_c('v-list-item-action',[_c('v-radio',{attrs:{"value":_vm.expire3}})],1),_c('v-list-item-title',[_vm._v("Tous ")])],1)],1):_vm._e()],1)],1),_c('v-spacer'),_c('v-row',[_c('v-text-field',{staticClass:"pr-6",attrs:{"autocomplete":"off","hide-details":"","single-line":"","solo-inverted":"","clearable":"","dense":"","flat":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.Add)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","dark":"","color":"indigo","disabled":_vm.add_disable || _vm.Loading},on:{"click":_vm.itemnew}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Ajouter")])])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('v-card',{staticClass:"ma-2",class:_vm.selecteditem.id == item.id ? 'selected' : '',attrs:{"elevation":"2"},on:{"click":function($event){return _vm.rowClicked(item, index)}}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.selection.filter(
                function (elm) { return elm.title == true; }
              )),function(header2,colIndex){return _c('v-col',{key:colIndex,staticClass:"h6",attrs:{"cols":"12","sm":header2.sm ? header2.sm : '6',"md":header2.md ? header2.md : '6'}},[(header2.slot == 'href')?_c('a',{on:{"click":function($event){return _vm.editItem(item, index)}}},[_vm._v(" "+_vm._s(header2.text + " " + (item[header2.value] ? item[header2.value] : ""))+" ")]):_c('div',[_vm._v(" "+_vm._s(header2.text + " " + (item[header2.value] ? item[header2.value] : ""))+" ")])])}),1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.selection.find(function (elm) { return elm.subtitle; }) ? item[_vm.selection.find(function (elm) { return elm.subtitle; }).value] : "")+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.selection.filter(
                function (elm) { return !elm.title && !elm.subtitle; }
              )),function(header,columnIndex){return _c('v-col',{key:columnIndex,attrs:{"cols":"12","sm":header.sm,"md":header.md}},[(header.value == 'no')?_c('div',[_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(index + 1)+" ")])],1):(
                  header.value == 'actions' &&
                  item[header.column] == header.valeur &&
                  item[header.column1] == header.valeur1 &&
                  (_vm.del || _vm.$store.state.isadmin)
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.delRecord(item, index)}}},[_vm._v(" mdi-delete ")]):(header.slot == 'rlink')?_c('div',[_c('a',{on:{"click":function($event){return _vm.routeto(item, index, header.router)}}},[_vm._v(_vm._s(item[header.value] ? header.text + " " + item[header.value] : ""))])]):(header.slot == 'href')?_c('a',{on:{"click":function($event){return _vm.editItem(item, index)}}},[_vm._v(" "+_vm._s(item[header.value] ? header.text + " " + item[header.value] : "")+" ")]):(header.slot == 'chip')?_c('v-chip',{attrs:{"color":item[header.color]}},[_vm._v(_vm._s(item[header.value] ? header.text + " " + item[header.value] : ""))]):(header.slot == 'checkbox')?_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}},[_vm._v(_vm._s())]):(header.slot == 'cur')?_c('div',{staticClass:"cur2"},[_vm._v(" "+_vm._s(header.text + " " + _vm.numberWithSpace( item[header.value] ? item[header.value].toFixed(2) : "0.00" ))+" ")]):(header.slot == 'date')?_c('div',[_vm._v(" "+_vm._s(item[header.value] ? header.text + " " + _vm.datefr(item[header.value]) : "")+" ")]):(header.slot == 'cur2')?_c('div',{staticClass:"cur"},[_vm._v(" "+_vm._s(header.text + ": " + _vm.numberWithSpace( item[header.value] ? item[header.value].toFixed(2) : "0.00" ))+" ")]):(header.value2)?_c('div',[_vm._v(" "+_vm._s(header.text + " " + item[header.value][header.value2])+" ")]):_c('p',{class:header.expire == true && item.expire
                    ? 'expired red--text'
                    : '',attrs:{"align":header.align ? header.align : 'left'}},[_vm._v(" "+_vm._s(item[header.value] ? header.text + " " + item[header.value] : "")+" ")])],1)}),1)],1)],1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-pagination',{attrs:{"length":_vm.TotalPage,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }