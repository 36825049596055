<template>
  <div>
    <v-data-table
      fixed-header
      dense
      single-select
      :items="items"
      item-key="id"
      :headers="selection"
      :loading="Loading"
      loading-text="Chargement en cours ... Merci de patienter"
      v-model="selectedRows"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': rowsPerPageItems,
        'disable-pagination': true,
      }"
      hide-default-header
      :key="klist"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-menu
            bottom
            right
            v-model="showMenu"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="Loading"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-radio-group
                v-model="expire"
                @change="ShowMore()"
                v-if="list_options"
              >
                <v-list-item>
                  <v-list-item-action>
                    <v-radio :value="expire1"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>En cours </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isExpire">
                  <v-list-item-action>
                    <v-radio :value="expire2"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>Expire </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-radio :value="expire3"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>Tous </v-list-item-title>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>
          <v-row>
            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
              placeholder="Search"
            >
            </v-text-field>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  dark
                  color="indigo"
                  @click="itemnew"
                  :disabled="add_disable || Loading"
                  v-if="Add"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Ajouter</span>
            </v-tooltip>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item, index }">
        <v-card
          :class="selecteditem.id == item.id ? 'selected' : ''"
          @click="rowClicked(item, index)"
          class="ma-2"
          elevation="2"
        >
          <v-card-title>
            <v-row no-gutters>
              <v-col
                cols="12"
                :sm="header2.sm ? header2.sm : '6'"
                :md="header2.md ? header2.md : '6'"
                v-for="(header2, colIndex) in selection.filter(
                  (elm) => elm.title == true
                )"
                :key="colIndex"
                class="h6"
              >
                <a @click="editItem(item, index)" v-if="header2.slot == 'href'">
                  {{
                    header2.text +
                    " " +
                    (item[header2.value] ? item[header2.value] : "")
                  }}
                </a>
                <div v-else>
                  {{
                    header2.text +
                    " " +
                    (item[header2.value] ? item[header2.value] : "")
                  }}
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            {{
              selection.find((elm) => elm.subtitle)
                ? item[selection.find((elm) => elm.subtitle).value]
                : ""
            }}
          </v-card-subtitle>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="12"
                :sm="header.sm"
                :md="header.md"
                v-for="(header, columnIndex) in selection.filter(
                  (elm) => !elm.title && !elm.subtitle
                )"
                :key="columnIndex"
              >
                <div v-if="header.value == 'no'">
                  <v-layout justify-center>
                    {{ index + 1 }}
                  </v-layout>
                </div>
                <v-icon
                  v-else-if="
                    header.value == 'actions' &&
                    item[header.column] == header.valeur &&
                    item[header.column1] == header.valeur1 &&
                    (del || $store.state.isadmin)
                  "
                  small
                  class="mr-2"
                  @click="delRecord(item, index)"
                >
                  mdi-delete
                </v-icon>
                <div v-else-if="header.slot == 'rlink'">
                  <a @click="routeto(item, index, header.router)">{{
                    item[header.value]
                      ? header.text + " " + item[header.value]
                      : ""
                  }}</a>
                </div>

                <a
                  @click="editItem(item, index)"
                  v-else-if="header.slot == 'href'"
                >
                  {{
                    item[header.value]
                      ? header.text + " " + item[header.value]
                      : ""
                  }}
                </a>

                <v-chip
                  :color="item[header.color]"
                  v-else-if="header.slot == 'chip'"
                  >{{
                    item[header.value]
                      ? header.text + " " + item[header.value]
                      : ""
                  }}</v-chip
                >
                <v-simple-checkbox
                  disabled
                  v-model="item[header.value]"
                  v-else-if="header.slot == 'checkbox'"
                  >{{
                }}</v-simple-checkbox>
                <div v-else-if="header.slot == 'cur'" class="cur2">
                  {{
                    header.text +
                    " " +
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(2)
                        : "0.00"
                    )
                  }}
                </div>
                <div v-else-if="header.slot == 'date'">
                  {{
                    item[header.value]
                      ? header.text + " " + datefr(item[header.value])
                      : ""
                  }}
                </div>
                <div v-else-if="header.slot == 'cur2'" class="cur">
                  {{
                    header.text +
                    ": " +
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(2)
                        : "0.00"
                    )
                  }}
                </div>
                <div v-else-if="header.value2">
                  {{ header.text + " " + item[header.value][header.value2] }}
                </div>

                <p
                  v-else
                  :class="
                    header.expire == true && item.expire
                      ? 'expired red--text'
                      : ''
                  "
                  :align="header.align ? header.align : 'left'"
                >
                  {{
                    item[header.value]
                      ? header.text + " " + item[header.value]
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="TotalPage"
      total-visible="7"
      @input="handlePageChange"
    ></v-pagination>

    <v-snackbar v-model="snackbar" top :color="snackbar_color">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
//const selecttree = () => import("../components/SelectTree.vue");

function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
export default {
  name: "listitemspage",
  props: {
    title: String,
    headers: Array,
    qSelect: Object,
    qSelectall: Object,
    Get_suf: String,
    PageResponse: String,
    AllResponse: String,
    field: String,
    filename: String,
    sheetname: String,
    Add: { type: Boolean, default: true },
    add_disable: { type: Boolean, default: false },
    Total: { type: Boolean, default: false },
    Kind: Number,
    VarScope: Number,
    TypeScope: Number,
    CatScope: Array,
    AttScope: Array,
    Service: Array,
    centrale: Array,
    isExpire: { type: Boolean, default: true },
    list_options: { type: Boolean, default: true },
    selitem: Object,
    expire1: { type: Number, default: 1 },
    expire2: { type: Number, default: 2 },
    expire3: { type: Number, default: 3 },
    search_elm: String,
    field_list: Array,
  },
  components: {},

  data: () => ({
    showMenu: false,
    fs: 100,
    ktree: 300,
    klist: 200,
    expire: 1,
    options: {},
    selecteditem: {},
    editeditem: {},
    selectedRows: [],
    search: null,
    snackbar: false,
    snackbar_timeout: -1,
    snackbar_text: "",
    snackbar_color: "primary",
    rowsPerPageItems: [5, 10, 20, 50, 100],
    items: [],
    TotalPage: 0,
    total: 0,
    page: 1,
    Loading: false,
    isClosed: true,
  }),

  computed: {
    keyform() {
      return this.fs;
    },
    selection() {
      return this.headers.filter((item) => {
        if (item.selected === true && item.hiden != true) {
          return item;
        }
      });
    },
  },
  watch: {
    search: debounce(function () {
      this.page = 1;
      this.expire = this.search ? this.expire3 : this.expire1;
      this.ShowMore();
    }, 1000),
    options: {
      handler() {
        this.ShowMore();
      },
      deep: true,
    },

    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          this.ShowMore();
        }
      },
      deep: true,
    },
  },
  created() {},

  mounted() {
    this.expire = this.expire1;
    this.search = this.search_elm;
  },

  methods: {
    datefr(date) {
      let d;
      if (date) {
        var result = new Date(date);
        d = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      }
      return d;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    closeForm() {
      this.editeditem = {};
      this.isClosed = true;
    },
    async allitems() {
      this.snackbar = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_timeout = -1;
      let response = [];
      this.Loading = true;
      await this.$apollo
        .query({
          query: this.qSelectall,
          variables: {
            Kind: this.Kind,
            VarScope: this.VarScope,
            CatScope: this.CatScope,
            TypeScope: this.TypeScope,
            AttScope: this.AttScope,
            Service: this.Service,
            expire: this.expire,
            centrale: this.centrale,
          },
        })
        .then((data) => {
          //this.items = data["data"][this.AllResponse];
          this.snackbar = false;
          response = data["data"][this.AllResponse];
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return response;
    },
    totcolumns(where) {
      this.selection.forEach((element) => {
        if (element.totquery) {
          this.$apollo
            .query({
              query: element.totquery,
              variables: {
                where: where,
                Kind: this.Kind,
                VarScope: this.VarScope,
                TypeScope: this.TypeScope,
                CatScope: this.CatScope,
                AttScope: this.AttScope,
                Service: this.Service,
                centrale: this.centrale,
              },
            })
            .then((data) => {
              element.totcol = data.data[element.result];
            })
            .catch((error) => {
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        }
      });
    },
    handlePageChange(value) {
      this.selecteditem = "";
      this.page = value;
      this.ShowMore();
    },
    ShowMore() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.showMenu = false;

        let field = this.field;
        let order = "DESC";
        let orderby = [];
        const { sortBy, sortDesc } = this.options;
        for (let index = 0; index < sortBy.length; index++) {
          const element = sortBy[index];
          field =
            this.headers[this.headers.findIndex((h) => h.value === element)]
              .enum;
          sortDesc[index] ? (order = "DESC") : (order = "ASC");
          if (this.field_list) {
            this.field_list.forEach((element) => {
              orderby.push({ column: element, order: order });
            });
          } else orderby.push({ column: field, order: order });
        }
        if (orderby.length == 0) {
          if (this.field_list) {
            this.field_list.forEach((element) => {
              orderby.push({ column: element, order: order });
            });
          } else orderby.push({ column: field, order: order });
        }
        let page = this.page;
        if (this.page - this.total / this.options.itemsPerPage > 1) {
          page = 1;
        }

        var where = null;
        if (this.search) {
          var i;
          var Wherelist = [];
          var x = "%";
          x = x.concat(this.search, "%");
          for (i in this.headers) {
            if (this.headers[i].selected && this.headers[i].enum) {
              Wherelist.push({
                column: this.headers[i].enum,
                operator: "LIKE",
                value: x,
              });
            }
          }
          if (Wherelist.length) where = { OR: Wherelist };
        }
        this.totcolumns(where);
        this.Loading = true;
        this.$apollo
          .query({
            query: this.qSelect,
            variables: {
              page: page,
              first: this.options.itemsPerPage,
              orderby: orderby,
              where: where,
              Kind: this.Kind,
              VarScope: this.VarScope,
              TypeScope: this.TypeScope,
              CatScope: this.CatScope,
              AttScope: this.AttScope,
              Service: this.Service,
              expire: this.expire,
              centrale: this.centrale,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.items = data["data"][this.PageResponse]["data"];
            if (this.selitem) {
              let i = this.items.findIndex((elm) => elm.id == this.selitem.id);
              if (i >= 0) {
                this.selecteditem = this.items[i];
                this.$emit("rowselect", this.selecteditem);
              } else this.$emit("rowselect", {});
            }
            this.TotalPage =
              data["data"][this.PageResponse]["paginatorInfo"]["lastPage"];
            this.total =
              data["data"][this.PageResponse]["paginatorInfo"]["total"];

            this.$store.dispatch("Changed", false);
            this.Loading = false;
          })
          .catch((error) => {
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
            this.Loading = false;
          });
        let items = this.items;
        let total = this.total;
        setTimeout(() => {
          resolve({
            items,
            total,
          });
        }, 1000);
      });
    },

    rowClicked(row, index) {
      row.index = index;
      this.selecteditem = row;
      this.$emit("rowselect", this.selecteditem);
    },
    filter() {
      this.$emit("filter");
    },
    unfilter() {
      this.$emit("unfilter");
    },
    itemnew() {
      this.editeditem = { id: -1 };
      this.fs++;
      this.$emit("open", this.editeditem);
    },
    startDownload() {
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
    },
    finishDownload() {
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
    },

    editItem(item, index) {
      item.index = index;
      this.fs++;
      this.editeditem = item;
      this.$emit("open", this.editeditem);
    },
    routeto(item, index, to) {
      item.index = index;
      this.$router.push({ name: to, params: { item: item } });
    },
    async delRecord() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        this.items.splice(this.selecteditem.index, 1);
        this.$apollo
          .mutate({
            mutation: this.qDelete,
            variables: {
              id: this.selecteditem.id,
            },
          })
          .then(() => {
            this.$store.dispatch("Changed", true);
            this.$emit("delete", this.selecteditem);
          })
          .catch((error) => {
            this.snackbar_color = "error";
            this.snackbar_text = error.message;
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
